.not_block {
  min-height: 50vh;
  padding: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & span {
    font-size: 18px;
    margin-top: 15%;
  }
}