.common {
    background-color: #edf6ff;

    &_block {
        padding: 4.6875rem 0 2rem;
        box-sizing: border-box;
    }
}

.common_2 {
    background-color: #FFFFFF;

    &_block {
        padding: 4.6875rem 0 2rem;
        box-sizing: border-box;
    }
}

.full_background_image {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: -1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}