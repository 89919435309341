.block {
  // margin-bottom: 12vh;
  
  &_center {
    text-align: center;
    color: #5791c0;
    font-size: 36px;
    font-weight: 500;
  }
  &_left,
  &_right {
    position: relative;
    &_round {
      width: 10px;
      height: 10px;
      border-radius: 50px;
      border: 2px solid #5791c0;
      background-color: #fff;
      position: absolute;
      z-index: 10;
      top: 12px;
    }
  }
  &_left,
  &_right {
    position: relative;
    &_rectangle {
      width: 95%;
      height: 2px;
      background-color: #5791c0;
      border: 0;
      position: absolute;
      z-index: 5;
      top: 18px;
    }
  }
  &_left {
    &_round {
      right: 0;
    }
  }
  @media (max-width: 767px) {
    &_left,
    &_right {
      display: none;
    }
    &_center {
      width: 100%;
      font-size: 24px;
      font-weight: 500;
    }
  }
}
