.banner_text {
    width: 100%;
    background-color: #fff;
}

.banner_content {
    min-height: 17vh;
    text-align: center;
    font-size: 18px;

    @media (min-width: 1024px) {
        font-size: 36px;
    }
}