.home_banner {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  &_img {
    width: 85%;
  }
  &_summary {
    width: 100%;
    &_title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 25px;
    }
    &_text {
      margin-bottom: 45px;
    }
  }
  &_container {
    height: 100%;
  }
  @media (max-width: 1023px) {
    &_summary {
      text-align: center;
    }
    &_controller_now {
      text-align: center;
    }
  }
  @media (min-width: 1024px) {
    &_row {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
      width: 100%;
    }
    &_summary {
      text-align: left;
      &_title {
        font-size: 34px;
        font-weight: 700;
      }
      &_text {
        font-size: 16px;
        line-height: 2.8;
      }
    }
    &_controller {
      position: relative;
      &_now {
        position: absolute;
        width: 100%;
        z-index: 100;
        bottom: 165px;
        -webkit-animation: bounce-down 1.6s linear infinite;
        animation: bounce-down 1.6s linear infinite;
        img {
          position: absolute;
          width: 90%;
        }
      }
    }
    @-webkit-keyframes bounce-down {
      25% {
        -webkit-transform: translateY(-4px);
      }
      50%,
      100% {
        -webkit-transform: translateY(0);
      }
      75% {
        -webkit-transform: translateY(4px);
      }
    }
    @keyframes bounce-down {
      25% {
        transform: translateY(-4px);
      }
      50%,
      100% {
        transform: translateY(0);
      }
      75% {
        transform: translateY(4px);
      }
    }
  }

  @media (min-width: 1366px) {
    &_controller_now {
      bottom: 205px;
    }
    &_summary {
      width: 100%;
      &_title {
        font-size: 40px;
        font-weight: 700;
      }
      &_text {
        font-size: 18px;
        line-height: 2;
      }
    }
  }
}
