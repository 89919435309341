.btc {
  &_block {
    padding: 75px 0 64px;
  }
  &_title {
    margin-bottom: 75px;
  }
  &_end_detail li:first-child {
    color: #0091ff;
  }
  &_end_detail li:last-child {
    margin: 15px;
    list-style: none;
  }
}

.Expand_17 {
  margin-top: 24px;
  padding: 5px;
  box-sizing: border-box;
  &_title {
    padding: 20px;
    border: 2px solid #0091ff;
    border-radius: 5px;
    & span {
      color: #0091ff;
    }
  }
  &_detail {
    line-height: normal;
    min-height: 150px;
    margin-top: 24px;
    padding: 20px;
    background-color: #f1f6fb;
    border-radius: 5px;
  }
}

.ectopicCard {
  background-color: #f1f6fb;
  &_block {
    padding: 24px;
    box-sizing: border-box;
  }
  &_title {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  &_detail {
    line-height: normal;
  }
  &_cover {
    width: 100%;
    height: 255px;
  }

  @media (min-width: 1024px) {
    position: relative;
    margin-top: 100px;
    display: flex;
    align-items: center;
    
    &_block {
      padding: 24px;
      box-sizing: border-box;
      &_left {
          align-self: flex-start;
        // position: absolute;
        // right: 0;
        // top: 50%;
        // transform: translateY(-50%);

        // display: flex;
        // flex-direction: column;
        // justify-content: center;
      }
    }
    &_title {
      font-size: 32px;
      font-weight: 500;
      margin-bottom: 15px;
    }
    &_detail,
    &_title {
      margin-left: 24px;
    }
    &_detail {
      line-height: normal;
    }
    &_cover {
      width: 100%;
      height: 255px;
    //   position: relative;
    //   bottom: 45px;
    //   display: flex;
    //   align-content: flex-start;
      position: absolute;
      top: -45px;
    }
  }
}
