.sw {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  position: relative;
  &_pagination {
    position: unset;
    &.sw_page_tag {
      border-radius: 5px 5px 0 0;
      background-color: #fff;
      list-style: none;
      display: flex;
      justify-items: center;
      align-items: center;
      margin: 0;
      padding: 0;
      &_sub_item {
        flex: 1;
        position: relative;
        padding: 15px 0;
      }
    }
  }
  &_image_block {
    padding: 85px 0;
    position: relative;
    &_content {
      width: 100%;
    }
  }
  &_image_border {
    padding: 15px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    width: 95%;
    height: 70%;
    position: absolute;
    top: 90px;
    left: 20px;
    border-radius: 15px 15px 25px 25px;
    -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.4),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    &_content {
      width: 100%;
    }
    &_top_bg {
      position: absolute;
      bottom: 95px;
    }
  }
  &_right_item {
    min-height: 450px;
    &_bg {
      position: absolute;
      z-index: 1;
      right: 125px;
      bottom: 100px;
      opacity: 0.5;
    }
    &_block {
      display: block;
      margin-right: auto;
      margin-left: auto;
    }
  }

  @media (max-width: 1366px) {
    &_image_border {
      height: 67%;
      &_top_bg {
        bottom: 90px;
      }
    }
    &_right_item {
      &_bg {
        right: 55px;
      }
    }
  }

  @media (max-width: 768px) {
    // ipad竖屏
    &_image_border {
      height: 62%;
    }
    &_right_item {
      &_bg {
        right: 15px;
        bottom: 15px;
      }
    }
  }

  @media (max-width: 600px) {
    // iPhone2pro竖屏
    &_image_block {
      padding: 35px;
      &_image_block_content {
        width: 85%;
      }
    }
    &_image_border {
      width: 75%;
      left: 12%;
      height: 68%;
      top: 15%;
      padding: 10px;
      &_content {
        height: 100%;
      }
      &_top_bg {
        bottom: 40px;
        width: 15%;
        left: 8%;
      }
    }
    &_right_item {
      min-height: 215px;
      &_bg {
        bottom: 45px;
      }
      &_block {
        margin-right: unset;
        margin-left: unset;
      }
    }
  }
}
