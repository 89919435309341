@import "./Source/iconfont/iconfont.css";

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
} /* 滚动条的滑轨背景颜色 */

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
} /* 滑块颜色 */

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
} /* 滑块颜色 */

::-webkit-scrollbar-button {
  background-color: rgba(0, 0, 0, 0);
  height: 0;
} /* 滑轨两头的监听按钮颜色 */

::-webkit-scrollbar-corner {
  background-color: black;
} /* 横向滚动条和纵向滚动条相交处尖角的颜色 */

body {
  background: #fff;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: MiSans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: MiSans, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bw_theme {
  &_hover {
    & a:hover,
    & button:hover {
      background-image: linear-gradient(to right, #00aeff, #0091ff);
      color: #fff;
      // font-weight: bold;
    }
  }
  &_blue {
    background-image: linear-gradient(to right, #00aeff, #0091ff);
    &_btn {
      color: #fff !important;
      font-weight: bold;
    }
  }
  &_red {
    background-image: linear-gradient(to right, #ff5722, #e91e63);
    &_blur {
      background-color: rgba(255, 0, 0, 0.5);
      color: #fff;
    }
  }
  &_white {
    background-color: #fff;
    &_blur {
      background-color: #333;
      color: #fff;
    }
  }
  &_odd_block_blue {
    background-color: #edf6ff !important;
  }
  &_odd_block_red {
    background-color: #ffeded !important;
  }
  &_blur_dark {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }
  &_blur_white {
    background-color: rgba(255, 255, 255, 0.5);
    color: #333;
  }
  &_active {
    color: #0091ff;
    background-color: aliceblue;
    padding: 0 4px;
    border-radius: 5px;
  }
}

.bw-block {
  background-color: #fff;
  padding: 12vh 0;
  box-sizing: border-box;
  &-no-bottom {
    padding: 12vh 0 0;
  }
  &-small {
    padding: 3vh 0;
  }
}

.sub_bw_block + .sub_bw_block {
  padding: 0 0 12vh;
}

.bw_blur {
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}
