.bw_logo {
  color: #4f8cbd;
}

.ike_logo {
  font-size: 28px;
}

.bw_menu_cascade {
  min-width: 168px;
  width: auto;
}

.sub_more {
  text-align: center;
  &_icon {
    position: absolute;
  }
}
