.instructions {
  padding: 0 0 100px;
  &_title {
    font-size: 22px;
    font-weight: 700;
    margin-top: 50px;
  }

  &_cover {
    margin: 65px 0px;
  }
  @media (min-width: 1024px) {
    &_summary {
      // font-size: 18px;
      color: #666;
    }
  }
  @media (max-width: 1023px) {
    &_summary,
    &_title {
      font-size: 20px;
      text-align: center;
    }
    &_summary {
      font-size: 16px;
    }
    padding: 0;
  }
}

.demo {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 2px;
  &_textarea {
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    border: 0;
    resize: none;
    background-color: aliceblue;
    &:focus {
      outline: none;
    }
  }
  &_top,
  &_footer {
    height: 45px;
    border: 0;
    border-bottom: 1px dashed #ccc;
  }
  &_top {
    height: auto;
  }
  &_center {
    height: 280px;
    background-color: aliceblue;
    &_2 {
      height: 325px;
      border-radius: 0 0 5px 5px;
    }
  }
  &_footer {
    height: 45px;
    border: 0;
    border-top: 1px dashed #ccc;
  }
  &_btn {
    margin: 5px;
    float: right;
    color: #fff;
  }
  &_btn:last-child {
    margin: 5px 0;
  }
}

.none_block {
  margin-bottom: 100px;
}

.custom_item {
  // margin-bottom: 75px;
  margin-bottom: 15px;

  &_btn:hover {
    color: #5791c0;
  }
  &_title {
    font-size: 18px;
    font-weight: 700;
    & span {
      color: #4f8cbd;
      margin-right: 10px;
    }
  }
  &_summary {
    color: #666;
  }
}

.none_block {
  margin-bottom: 100px;
}
