.footer {
  white-space: nowrap;
  &_title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
  }
}

.beian {
  margin-top: 50px;
  cursor: pointer;
  @media (min-width: 1024px) {
    text-align: center;
  }
}
