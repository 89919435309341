.Expand4,
.Expand6,
.Expand8,
.Expand14 {
  &_block {
    padding: 75px 0 64px;
  }
  &_block2 {
    padding: 0 0 64px;
  }
  &_title {
    margin-bottom: 75px;
  }
}

.Expand5,
.Expand9,
.Expand11,
.Expand15 {
  &_block {
    background-color: #fff;
    padding: 75px 0 64px;
  }
  &_block_2 {
    background-color: #fff;
    padding: 0 0;
  }
  &_title {
    margin-bottom: 75px;
  }
}

.Expand10 {
  &_block {
    padding: 0 0 64px;
  }
  &_title {
    margin-bottom: 75px;
  }
}

.banner_content {
  min-height: 17vh;
  text-align: center;
  font-size: 18px;
  @media (min-width: 1024px) {
    font-size: 36px;
  }
}

.banner_text {
  width: 100%;
  background-color: #fff;
}

.template_background {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.card {
  margin-bottom: 45px;
  @media (min-width: 1024px) {
    padding: 0 75px 75px;
  }
}

.instructions {
  padding: 0 0 100px;
  &_title {
    font-size: 22px;
    font-weight: 700;
    margin-top: 50px;
  }

  &_cover {
    margin: 65px 0px;
  }
  @media (min-width: 1024px) {
    &_summary {
      color: #666;
    }
  }
  @media (max-width: 1023px) {
    &_summary,
    &_title {
      font-size: 20px;
      text-align: center;
    }
    &_summary {
      font-size: 16px;
    }
    padding: 0;
  }
}

.ectopicCard {
  background-color: #f1f6fb;
  &_block {
    padding: 24px;
    box-sizing: border-box;
  }
  &_title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  &_detail {
    line-height: normal;
  }
  &_cover {
    width: 100%;
    height: 255px;
  }

  @media (min-width: 1024px) {
    position: relative;
    margin-top: 100px;
    &_block {
      padding: 24px;
      box-sizing: border-box;
    }
    &_title {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 15px;
    }
    &_detail,
    &_title {
      margin-left: 24px;
    }
    &_detail {
      line-height: normal;
    }
    &_cover {
      width: 100%;
      height: 255px;
      position: absolute;
      top: -45px;
    }
  }
}

.Expand_17 {
  margin-top: 24px;
  padding: 5px;
  box-sizing: border-box;
  &_title {
    padding: 20px;
    border: 2px solid #0091ff;
    border-radius: 5px;
    & span {
      color: #0091ff;
    }
  }
  &_detail {
    line-height: normal;
    min-height: 150px;
    margin-top: 24px;
    padding: 20px;
    background-color: #f1f6fb;
    border-radius: 5px;
  }
}
