.title {
  font-size: 22px;
  font-weight: 500;
  margin: 45px 0 25px;
}

.detail ul {
  list-style: none;
  padding-inline-start: 0;
  margin: 35px 0;
  & li {
    padding: 15px;
    &:nth-child(odd) {
      background-color: aliceblue;
    }
  }
}

.detail_explain {
  border: 1px dashed #ccc;
  border-radius: 5px;
  margin: 45px 0;
  & legend {
    font-weight: 500;
  }
  & ul {
    list-style: none;
    & li {
      padding: 8px;
    }
  }
  @media (max-width: 600px) {
    & ul {
      padding-inline-start: 0;
    }
  }
}
