.sw_page_tag {
  border-radius: 5px 5px 0 0;
  background-color: #fff;
  list-style: none;
  display: flex;
  justify-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  &_sub_item {
    flex: 1;
    position: relative;
    padding: 15px 0;
  }
}
.sw_page_tag_sub_item {
  flex: 1;
  position: relative;
  padding: 24px 0;
}

.sw_page_active::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-image: linear-gradient(
    to right,
    rgb(154, 223, 255),
    rgb(0, 145, 255)
  );
  bottom: 0;
  animation-name: tabIsClick;
  animation-duration: 3s;
}

// @keyframes loading {
//     0% {
//       width: 0%;
//       left: 0%;
//     }
//     50% {
//       width: 100%;
//       left: 0%;
//     }

//     100% {
//       width: 0%;
//       left: 100%;
//     }
//   }
@keyframes tabIsClick {
  0% {
    width: 0%;
    left: 0%;
  }
  100% {
    width: 100%;
    left: 0%;
  }
}
