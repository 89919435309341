.header {
  padding: 15px;
  &_contentList {
    padding: 10px 10px;
    padding-left: 15px;
    border-bottom: 1px dashed #eee;
    &:hover {
      cursor: pointer;
      background-color: aliceblue;
    }
  }
}

.pageBtn {
  margin: 45px 0;
}
