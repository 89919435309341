.card_title:hover {
  cursor: pointer;
}
.bw_card {
  margin-top: 18px;
  &_media img {
    transition: all 0.6s;
  }
  &_media img:hover {
    transform: scale(1.4);
  }
}
