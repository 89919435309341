.chain {
  &_card_block {
    margin-bottom: 45px;
  }
  &_last_card_block {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  &_card_summary {
    white-space: normal;
  }

  @media (max-width: 768px) {
    &_card_block {
      margin-bottom: 0;
    }
    &_last_card_block {
      padding: 0 8px;
      display: block;
      margin-right: unset;
      margin-left: unset;
    }
  }
}
