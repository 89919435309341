.bread {
  clear: right;
  & ul {
    margin: 25px 0 !important;
    list-style: none;
    float: right;
    clear: left;
    & li {
      float: left;
      & a {
        text-decoration: none;
        color: #333;
      }
    }
    & li:last-child {
      display: none;
      & a {
        color: #0091ff;
      }
    }
    & li:nth-child(n + 2) {
      margin: 0 5px;
    }
  }
}
