.feedback {
  &_title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 25px;
  }
  &_input,
  &_textarea {
    // width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 8px 10px;
    margin-bottom: 10px;
    resize: none;
  }
  &_input:focus,
  &_textarea:focus {
    outline-color: #327abc;
    background-color: aliceblue;
  }
}

.feedback_err_tip {
  color: rgb(255, 86, 86);
}