.banner {
  padding: 12vh 0;
  color: #fff;
  &_title {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 45px;
  }
  &_summary {
    font-size: 18px;
    line-height: 2;
  }
  @media (max-width: 768px) {
    &_title {
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 45px;
    }
    &_summary {
      font-size: 16px;
    }
  }
}
