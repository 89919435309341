.bw_card {
  margin-bottom: 18px;
  &_topbar {
    padding: 6px 0;
    color: #fff;
    &_icon {
      font-size: 32px;
      height: auto;
    }
    &_title {
      font-weight: 500;
      font-size: 20px;
    }
  }
  &_content {
    min-height: 300px;
    max-height: 300px;
    height: 300px;
    overflow: auto;
    border-bottom: 1px dashed #eee;
  }
  &_msg_title {
    font-weight: bold;
  }
}
