.card {
  padding: 54px 0 0;
  box-sizing: border-box;
  &_block {
    padding: 54px 0;
    box-sizing: border-box;
    &_img, &_text {
      padding: 32px;
    }
  }
}

.card_block {
  padding-bottom: 54px;
}

@media (min-width: 1024px) {
  .imgTextContent {
    display: flex;
    flex-direction: column;
    .itemBox {
      display: flex;
      align-items: center;
      .itemImg {
        flex: 5 1;
      }
      .itemContext {
        flex: 7 1;
        padding: 32px;
        box-sizing: border-box;
        text-align: left;
      }
      &.isEven {
        .itemImg {
          order: 1;
        }
      }
    }
  }
}

.text_block {
  min-height: 250px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img_block {
  padding: 25px;
}
