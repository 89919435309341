@font-face {
  font-family: "iconfont"; /* Project id 2819746 */
  src: url('iconfont.woff2?t=1693301830628') format('woff2'),
       url('iconfont.woff?t=1693301830628') format('woff');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-yike-02:before {
  content: "\e6f5";
}

.a-ziyuan6:before {
  content: "\e6ed";
}

.logo:before {
  content: "\e6f4";
}

.close:before {
  content: "\e640";
}

.menu_btn:before {
  content: "\e6f3";
}

.language:before {
  content: "\e6f2";
}

.download:before {
  content: "\e6f1";
}

.bottom:before {
  content: "\e6f0";
}

.right:before {
  content: "\e6ee";
}

.left:before {
  content: "\e6ef";
}

.zd1:before {
  content: "\e6eb";
}

.zd:before {
  content: "\e6ec";
}

.xl:before {
  content: "\e6cf";
}

.zy:before {
  content: "\e6d0";
}

.sc:before {
  content: "\e6d1";
}

.cb:before {
  content: "\e6d2";
}

.emo:before {
  content: "\e6d3";
}

.cy:before {
  content: "\e6d4";
}

.emo1:before {
  content: "\e6d5";
}

.cz1:before {
  content: "\e6d6";
}

.cy1:before {
  content: "\e6d7";
}

.cz:before {
  content: "\e6d8";
}

.fl1:before {
  content: "\e6d9";
}

.jc1:before {
  content: "\e6da";
}

.jc:before {
  content: "\e6db";
}

.fl:before {
  content: "\e6dc";
}

.xsd1:before {
  content: "\e6dd";
}

.xsd:before {
  content: "\e6de";
}

.tq:before {
  content: "\e6df";
}

.tq1:before {
  content: "\e6e0";
}

.xc1:before {
  content: "\e6e1";
}

.xc:before {
  content: "\e6e2";
}

.gj:before {
  content: "\e6e3";
}

.ly:before {
  content: "\e6e4";
}

.kf:before {
  content: "\e6e5";
}

.r:before {
  content: "\e6e6";
}

.pt:before {
  content: "\e6e7";
}

.xx:before {
  content: "\e6e8";
}

.zn:before {
  content: "\e6e9";
}

.fa:before {
  content: "\e6ea";
}

